

































































































































































import * as R from 'ramda';
import { MonitoringAPI } from '@/app/api';
import { TailwindTable, WeatherDynamicIcon } from '@/app/components';
import { useAxios, useFilters } from '@/app/composable';
import { ColumnPosition, ColumnType } from '@/app/constants';
import { MonitoringExecutionQuery } from '@/app/types';
import { CheckCircleIcon, DotsCircleHorizontalIcon, QuestionMarkCircleIcon, XCircleIcon } from '@vue-hero-icons/solid';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ExecutionError from './ExecutionError.vue';

dayjs.extend(relativeTime);

export default defineComponent({
    name: 'IncidentPerDcj',
    props: {
        query: { type: Object as PropType<MonitoringExecutionQuery>, required: true },
    },
    components: {
        TailwindTable,
        CheckCircleIcon,
        XCircleIcon,
        DotsCircleHorizontalIcon,
        WeatherDynamicIcon,
        ExecutionError,
        QuestionMarkCircleIcon,
    },
    setup(props, { root, emit }) {
        const { exec, loading } = useAxios(true);
        const { fromNow: dateFromNow, formatDateAs: formatDate } = useFilters();

        const analytics: Ref<any> = ref<any>();

        const fetchAnalytics = async (query: MonitoringExecutionQuery) => {
            await exec(MonitoringAPI.executionIncidents(query))
                .then((res: any) => {
                    analytics.value = res.data;
                    emit('update-show-results-warning', res.data.pagination.total >= 1000);
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const columns = computed(() => [
            {
                key: 'jobId',
                label: 'ID',
                type: ColumnType.Integer,
                showAfter: 'sm',
                link: (value: number) => {
                    return value ? { name: 'dcj:history', params: { id: `${value}` } } : null;
                },
            },
            {
                key: 'id',
                label: 'Pipeline ID',
                type: ColumnType.Uid,
                showAfter: 'sm',
                copy: true,
            },
            {
                key: 'name',
                label: 'Pipeline',
                type: ColumnType.Text,
            },
            {
                key: 'failedExecutions',
                label: 'Failed Executions',
                type: ColumnType.Integer,
                showAfter: 'md',
                position: ColumnPosition.Center,
                sortable: true,
            },
            {
                key: 'metrics',
                label: 'Completeness',
                type: ColumnType.Object,
                showAfter: 'xl',
                position: ColumnPosition.Center,
            },
            {
                key: 'errors',
                label: 'Most Common Failure Reason',
                type: ColumnType.Array,
                showAfter: 'xl',
            },
            {
                key: 'lastExecution',
                label: 'Last Execution',
                type: ColumnType.Object,
            },
        ]);

        const rows = computed(() => analytics.value?.results);
        const rejectedItems = computed(
            () => (analytics.value?.accessControl?.rejectedItems ?? 0) + (analytics.value?.deletedPipelines ?? 0),
        );
        const pagination = computed(() => analytics.value?.pagination);

        const changeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        watch(
            () => props.query,
            (query: MonitoringExecutionQuery, oldQuery: MonitoringExecutionQuery | undefined) => {
                if (!oldQuery || !R.equals(oldQuery, query)) fetchAnalytics(query);
            },
            { deep: true, immediate: true },
        );

        watch(
            () => loading.value,
            (l: boolean) => emit('loading', l),
            { immediate: true },
        );

        return {
            rows,
            pagination,
            loading,
            columns,
            dateFromNow,
            formatDate,
            changeSort,
            rejectedItems,
            R,
        };
    },
});
