var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1 overflow-hidden"},[_c('TailwindTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination":_vm.pagination,"loading":_vm.loading,"sortBy":_vm.query.sortBy},on:{"change-page":function($event){return _vm.$emit('change-page', $event)},"sort":_vm.changeSort},scopedSlots:_vm._u([{key:"jobId",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?[_vm._v(_vm._s(formattedValue))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("-")])]}},{key:"jobId-tooltip",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?[_vm._v(_vm._s(formattedValue))]:_c('span',{staticClass:"italic"},[_vm._v("Data Check-in Pipeline does not exist")])]}},{key:"workflowId",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?[_vm._v(_vm._s(formattedValue))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("-")])]}},{key:"asset",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [(value)?[_vm._v(_vm._s(value.name))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Asset "+_vm._s(row.assetId)+" does not exist")])]}},{key:"asset-tooltip",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [(value)?_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('span',{staticClass:"px-2 pt-1 pb-0.5 text-xs rounded bg-neutral-200 text-neutral-900"},[_vm._v(" ID "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(value.id))])]),_c('span',[_vm._v(" "+_vm._s(value.name)+" ")])]),_c('div',{staticClass:"max-w-sm text-xs italic truncate text-neutral-200"},[_vm._v(" "+_vm._s(value.description)+" ")])]):_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"max-w-sm text-xs italic truncate text-neutral-200"},[_vm._v(" Asset "+_vm._s(row.assetId)+" does not exist any more ")])])]}},{key:"recordsStored",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"flex flex-row items-center space-x-1 text-sm"},[_c('div',{staticClass:"px-1 py-0.5 text-white bg-green-600 rounded"},[_vm._v(" "+_vm._s(value)+" ")])])]}},{key:"recordsStored-tooltip",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"flex flex-row items-center justify-between space-x-3 text-sm"},[_c('div',[_vm._v(_vm._s(value === 1 ? 'Record' : 'Records')+" successfully stored")]),_c('div',{staticClass:"px-1 py-0.5 text-xs text-white bg-green-600 rounded"},[_vm._v(" "+_vm._s(value)+" ")])])]}},{key:"recordsDropped",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_c('div',{staticClass:"flex flex-row items-center space-x-1 text-sm"},[_c('div',{staticClass:"px-1 py-0.5 text-white bg-yellow-600 rounded"},[_vm._v(" "+_vm._s(row.initialRecords > 0 ? ((value / row.initialRecords) * 100).toFixed(0) : 0)+"% ")])])]}},{key:"recordsDropped-tooltip",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_c('div',{staticClass:"flex flex-row items-center justify-between space-x-3 text-sm"},[_c('div',[_vm._v("Percentage of records dropped")]),_c('div',{staticClass:"px-1 py-0.5 text-xs text-white bg-yellow-600 rounded"},[_vm._v(" "+_vm._s(row.initialRecords > 0 ? ((value / row.initialRecords) * 100).toFixed(0) : 0)+"% ")])])]}},{key:"recordsTransformed",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_c('div',{staticClass:"flex flex-row items-center space-x-1 text-sm"},[_c('div',{staticClass:"px-1 py-0.5 text-white bg-blue-600 rounded"},[_vm._v(" "+_vm._s(row.initialRecords > 0 ? ((value / row.initialRecords) * 100).toFixed(0) : 0)+"% ")])])]}},{key:"recordsTransformed-tooltip",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_c('div',{staticClass:"flex flex-row items-center justify-between space-x-3 text-sm"},[_c('div',[_vm._v("Percentage of the records transformed")]),_c('div',{staticClass:"px-1 py-0.5 text-xs text-white bg-blue-600 rounded"},[_vm._v(" "+_vm._s(row.initialRecords > 0 ? ((value / row.initialRecords) * 100).toFixed(0) : 0)+"% ")])])]}},{key:"completeness",fn:function(ref){
var value = ref.value;
return [(value !== null)?_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('WeatherDynamicIcon',{attrs:{"weather":1 - value}}),_c('div',[_vm._v(_vm._s((value * 100).toFixed(2))+" %")])],1):_c('div',{staticClass:"italic text-neutral-500"},[_vm._v("Not calculated")])]}},{key:"completeness-tooltip",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('WeatherDynamicIcon',{attrs:{"weather":1 - value}}),_c('div',[_vm._v(" Presence of non-null values compared to total values: "),_c('span',{staticClass:"font-bold whitespace-nowrap"},[_vm._v(_vm._s((value * 100).toFixed(2))+"%")])])],1):_c('div',{staticClass:"italic text-neutral-500"},[_vm._v("No completeness was calculated")])]}},{key:"reliability",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [(value !== null)?_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('WeatherDynamicIcon',{attrs:{"weather":1 - value}}),_c('div',{staticClass:"whitespace-nowrap"},[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(row.successfulExecutions))]),_vm._v(" out of "+_vm._s(row.expectedExecutions)+" ")])],1):_c('div',{staticClass:"italic text-neutral-500"},[_vm._v("Not calculated")])]}},{key:"reliability-tooltip",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-row items-center space-x-2 text-sm"},[_c('WeatherDynamicIcon',{attrs:{"weather":1 - value}}),_c('div',[_vm._v(" Percentage of successful executions that loaded data to the asset compared to the total expected executions: "),_c('span',{staticClass:"font-bold whitespace-nowrap"},[_vm._v(_vm._s((value * 100).toFixed(2))+"%")])])],1):_c('div',{staticClass:"italic text-neutral-500"},[_vm._v("No reliability was calculated")])]}},{key:"footerRow",fn:function(){return [(_vm.rejectedItems)?[_vm._v(" Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted ")]:_vm._e()]},proxy:true},{key:"noRowsMessage",fn:function(){return [(!_vm.rejectedItems)?_c('span',[_vm._v("No data to show")]):_c('span',[_vm._v("Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }